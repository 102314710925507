import React from 'react';
import { Typography } from "antd";

const { Title } = Typography;

const Index = () => {
  return (
    <>
      <Title>udok.io blog</Title>
    </>
  );
};

export default Index;
